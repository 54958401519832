import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import {selectIsLoggedIn} from '../../../model/authentication';

export default function AuthenticationSwitch({children}) {
    const isLoggedIn = useSelector(selectIsLoggedIn);
    return children(isLoggedIn);
}

AuthenticationSwitch.propTypes = {
    children: PropTypes.func.isRequired,
};

export const IfLoggedIn = ({children}) => (
    <AuthenticationSwitch>
        {(isLoggedIn) => (isLoggedIn === true ? children : null)}
    </AuthenticationSwitch>
);

IfLoggedIn.propTypes = {
    children: PropTypes.any,
};

export const IfNotLoggedIn = ({children}) => (
    <AuthenticationSwitch>
        {(isLoggedIn) => (isLoggedIn === false ? children : null)}
    </AuthenticationSwitch>
);

IfNotLoggedIn.propTypes = {
    children: PropTypes.any,
};

export const IfLoggedInUnknown = ({children}) => (
    <AuthenticationSwitch>
        {(isLoggedIn) => (isLoggedIn == null ? children : null)}
    </AuthenticationSwitch>
);

IfLoggedInUnknown.propTypes = {
    children: PropTypes.any,
};
