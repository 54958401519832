import React, {Fragment, useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import Layout from '../ui/layout/Layout';
import {Section, Container} from '../ui/layout/Grid';
import Headline from '../ui/components/Headline';
import Link from '../ui/components/Link';
import {
    IfLoggedIn,
    IfNotLoggedIn,
    IfLoggedInUnknown,
} from '../ui/utils/AuthenticationSwitch';
import {
    IfNoRole,
    IfUserRole,
    IfInterimRole,
    IfTestUserRole,
    IfSalesRepresentativeRole,
} from '../ui/utils/RoleSwitch';
import {login, logout, sendEmailToResetPassword} from '../model/authentication';
import {
    loadOne as loadOnePharmacy,
    changePharmacy,
    loadListByZip,
} from '../model/pharmacy';
import {
    submitForm as submitRegisterForm,
    resendActivationEmail,
} from '../model/registration';
import {locationChange} from '../model/navigation/actions';
import {
    loadUserProfile,
    updateUserProfile,
    selectUser,
    setTempUserSettings,
    updateUserSettings,
    selectUserCategories,
} from '../model/profile';
import {selectCategories} from '../model/training';
import {selectCurrentPharmacyId} from '../model/pharmacy/selectors';

import {routes} from '../model/navigation';
import {selectById} from '../model/training';
import {Select} from '../ui/components/Form/Select';

import {submitForm as submitContactForm} from '../model/contact';
import PointsAssignmentForm from '../ui/domains/sales-agent-user-interactions/PointsAssignmentForm';
import {submitRewardOrder} from '../model/reward';

import {
    selectPendingReceipts,
    confirmPendingTransaction,
    rejectPendingTransaction,
    dismissPendingNotification,
} from '../model/points-receipt';

const userTypeOptions = [
    {label: 'PTA', value: 'PTA'},
    {label: 'PKA', value: 'PKA'},
    {label: 'Apotheker', value: 'Apotheker/in'},
    {
        label: 'Auszubildener',
        value: 'Auszubilender',
    },
];

const Debug = () => {
    const currentPharmacyId = useSelector(selectCurrentPharmacyId);
    const profile = useSelector(selectUser);
    const dispatch = useDispatch();
    const allTrainings = Object.values(useSelector(selectById));
    const [email, updateEmailInput] = useState(
        'jens.christiansen@antwerpes.com'
    );
    const availableCategories = useSelector(selectCategories);
    const optionsCategories = availableCategories.map((category) => ({
        value: category.id,
        label: category.name,
    }));
    const userCategories = useSelector(selectUserCategories);
    const [userType, setUserType] = useState({label: 'PTA', value: 'PTA'});
    const [categories, setCategories] = useState([]);
    useEffect(() => {
        setCategories(userCategories.map((id) => ({value: id})));
    }, [userCategories]);
    const pendingReceipts = useSelector(selectPendingReceipts);

    return (
        <Layout>
            <Section>
                <Container width="medium">
                    <Headline type="h4">Authentication</Headline>
                    <div>
                        <button
                            onClick={() => dispatch(login('jens', 'secret'))}>
                            login='secret'
                        </button>
                        <button
                            onClick={() =>
                                dispatch(login('jens123', 'secret123'))
                            }>
                            login='secret123'
                        </button>
                        <button onClick={() => dispatch(logout())}>
                            logout
                        </button>
                        <span>
                            isLoggedIn=
                            <IfLoggedIn>yes</IfLoggedIn>
                            <IfNotLoggedIn>no</IfNotLoggedIn>
                            <IfLoggedInUnknown>unknown</IfLoggedInUnknown>
                        </span>
                    </div>
                    <IfNotLoggedIn>
                        <div>
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    dispatch(sendEmailToResetPassword(email));
                                }}>
                                <label>Send email to reset password</label>
                                <input
                                    type="email"
                                    placeholder="email"
                                    value={email}
                                    onChange={(e) =>
                                        updateEmailInput(e.target.value)
                                    }
                                />
                                <input type="submit" value="submit" />
                            </form>
                        </div>
                    </IfNotLoggedIn>
                    <Headline type="h4">Roles</Headline>
                    <div>
                        <IfNoRole>no role (unauthenticated user)</IfNoRole>
                        <IfUserRole>normal logged in user</IfUserRole>
                        <IfInterimRole>
                            normal logged in user that has no pharmacy
                            associated
                        </IfInterimRole>
                        <IfTestUserRole>
                            logged in special test user ("Präsentationsuser")
                        </IfTestUserRole>
                        <IfSalesRepresentativeRole>
                            logged in sales representative
                            ("Außendienstmitarbeiter")
                        </IfSalesRepresentativeRole>
                    </div>
                    <Headline type="h4">Pharmacies</Headline>
                    <div>
                        <button onClick={() => dispatch(loadOnePharmacy(1))}>
                            Load one pharmacy with id=1
                        </button>
                        <div>
                            <button
                                onClick={() => dispatch(loadListByZip(50321))}>
                                Load all pharmacies with zip code = 50321
                            </button>
                        </div>
                        <div>
                            <button
                                onClick={() => {
                                    if (currentPharmacyId === 1) {
                                        dispatch(changePharmacy(2, '441232'));
                                    } else {
                                        dispatch(changePharmacy(1, '13025294'));
                                    }
                                }}>
                                toggle pharmacy of user
                            </button>
                            <span>current id: {currentPharmacyId}</span>
                        </div>
                    </div>
                    <Headline type="h4">Registration</Headline>
                    <div>
                        choose user type
                        <Select
                            name="userType"
                            value={userType}
                            options={userTypeOptions}
                            onChange={(value) => setUserType(value)}
                        />
                        choose favorite categories
                        <Select
                            name="categories"
                            multi={true}
                            value={categories}
                            options={optionsCategories}
                            onChange={(values) => setCategories(values)}
                        />
                        <div>
                            <button
                                onClick={() => {
                                    dispatch(
                                        setTempUserSettings(
                                            categories.map(
                                                (entry) => entry.value
                                            ),
                                            userType.value
                                        )
                                    );
                                }}>
                                set categories and user type in the temp
                                directory
                            </button>
                        </div>
                        <button
                            onClick={() =>
                                dispatch(
                                    submitRegisterForm({
                                        salutation: 'Frau',
                                        username: 'barbaros',
                                        password: 'secret',
                                        email:
                                            'barbaros.bayraktar@antwerpes.com',
                                        passwordConfirmation: 'secret',
                                        firstName: 'Barbaros',
                                        lastName: 'Bayraktar',
                                        pharmacyId: 13,
                                        identificationNumber: '22979829',
                                        termsOfUse: 1,
                                        privacyPolicy: 1,
                                    })
                                )
                            }>
                            Sign up a new user
                        </button>
                        {/* <RegisterForm /> */}
                        <button
                            onClick={() =>
                                dispatch(resendActivationEmail('jens'))
                            }>
                            Resend activation email
                        </button>
                    </div>
                    <div>
                        <button
                            onClick={() =>
                                dispatch(
                                    locationChange({
                                        pathname: '/konto-aktivieren',
                                        search:
                                            '?token=eyJpdiI6ImdyMGtIOXF1UmYrMTJGU2Z4UFJOaXc9PSIsInZhbHVlIjoiYmNOMDJ4VFArYk92a0RKbTJLTjMrbWs5MTA4TTM4bG1TblBCZmorbUgrKzY0aTBadC9QdTNlQXI2Rldwb2N1T0c5c1l2OUtIR3BHNTVoeXVla0ZDc1dpMVZvRU43TVhiTzV2eTVjQ29oMHptMngxQzBMbE80TzdtTVNDRy9sR2MiLCJtYWMiOiJhYWM2ZTE4NTAzY2VmNjIyODRmODkxNDVmNjdlMDRmZWRlODc2MTVmNDAyMDllNzkwMTNmODhmMDY0ZGUzMTUzIn0%3D',
                                    })
                                )
                            }>
                            Submit activation
                            token(jens.christiansen@antwerpes.com)
                        </button>
                        <button
                            onClick={() =>
                                dispatch(
                                    locationChange({
                                        pathname: '/konto-aktivieren',
                                        search:
                                            '?token=eyJpdiI6IkpiVlRCcW9rSE04OW9sQ2ZPd2hNZUE9PSIsInZhbHVlIjoiRi9uMWRpc21VYy9BTmd3bEJoNkNQWUNjVTBmY1BCbmt0cE13Vk5nQjBuVitMM1gyZzhRVVAyNjM0TlVRRlFOZytxYXUyVlBud0NRbU1FQUZDUVBlT0x4ZzYyZ1lGbFlhQzcrTlNKb2VhYnhwRzhVV05SV3FjN0RtTm1rbXdYd1EiLCJtYWMiOiIxOWNmZDhmNzg5MDI5ZGM4ZDJiOTI1YmQzN2I5N2U5ODkxM2ZjZjdkNGIzMWY3YmRkY2EwNWQ3MzE4MzhiMjAwIn0%3D',
                                    })
                                )
                            }>
                            Submit activation
                            token(jens.christiansen@antwerpes.de)
                        </button>
                    </div>
                    <Headline type="h4">Benutzerprofil</Headline>
                    <div>
                        <button onClick={() => dispatch(loadUserProfile())}>
                            Load profile
                        </button>
                    </div>
                    <div>
                        <button
                            onClick={() => {
                                if (profile.firstName === 'Petra') {
                                    dispatch(
                                        updateUserProfile({
                                            firstName: 'Jens',
                                            lastName: 'Christiansen',
                                            salutation: 'Herr',
                                        })
                                    );
                                } else {
                                    dispatch(
                                        updateUserProfile({
                                            firstName: 'Petra',
                                            lastName: 'Meyer',
                                            salutaion: 'Frau',
                                        })
                                    );
                                }
                            }}>
                            Toggle personal data
                        </button>
                        {profile && (
                            <span>
                                firstname: {profile.firstName}, lastname:{' '}
                                {profile.lastName}, salutation:{' '}
                                {profile.salutation}
                            </span>
                        )}
                    </div>
                    <div>
                        <button
                            onClick={() =>
                                dispatch(
                                    updateUserProfile({
                                        username: 'jens',
                                        password: 'secret',
                                        passwordConfirmation: 'secret',
                                    })
                                )
                            }>
                            Switch to usernname=jens, password="secret"
                        </button>
                    </div>
                    <div>
                        <button
                            onClick={() =>
                                dispatch(
                                    updateUserProfile({
                                        username: 'jens123',
                                        password: 'secret123',
                                        passwordConfirmation: 'secret123',
                                    })
                                )
                            }>
                            Switch to usernname=jens123, password="secret123"
                        </button>
                    </div>
                    <div>
                        <button
                            onClick={() => {
                                if (
                                    profile.email ===
                                    'jens.christiansen@antwerpes.com'
                                ) {
                                    dispatch(
                                        updateUserProfile({
                                            email:
                                                'jens.christiansen@antwerpes.de',
                                        })
                                    );
                                } else {
                                    dispatch(
                                        updateUserProfile({
                                            email:
                                                'jens.christiansen@antwerpes.com',
                                        })
                                    );
                                }
                            }}>
                            Toggle email
                        </button>
                        {profile && <span>email: {profile.email}</span>}
                    </div>
                    <div>
                        Update user settings (categories, user type)
                        <div>
                            choose user type
                            <Select
                                name="userType"
                                value={userType}
                                options={userTypeOptions}
                                onChange={(value) => setUserType(value)}
                            />
                            choose categories
                            <Select
                                name="categories"
                                multi={true}
                                value={categories}
                                options={optionsCategories}
                                onChange={(values) => setCategories(values)}
                            />
                        </div>
                        <button
                            onClick={() =>
                                dispatch(
                                    updateUserSettings(
                                        categories.map(
                                            (category) => category.value
                                        ),
                                        userType.value
                                    )
                                )
                            }>
                            Update user settings
                        </button>
                    </div>
                    <Headline type="h4">Kontaktformular</Headline>
                    <button
                        onClick={() =>
                            dispatch(
                                submitContactForm({
                                    email: 'jens.christiansen@antwerpes.de',
                                    message:
                                        'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.',
                                    lastName: 'Roberts',
                                    firstName: 'Julia',
                                    salutation: 'Frau',
                                })
                            )
                        }>
                        Submit contact form
                    </button>
                    <div>
                        <Headline type="h4">Außendienst Punktevergabe</Headline>
                        <PointsAssignmentForm />
                    </div>
                    <Headline type="h4">Prämien</Headline>
                    <button onClick={() => dispatch(submitRewardOrder(1))}>
                        Order Reward id=1
                    </button>
                    <Headline type="h4">Pending Transactions</Headline>
                    <ol>
                        {pendingReceipts.map((receipt) => (
                            <li key={`${receipt.type}-${receipt.id}`}>
                                [type: {receipt.type} id: {receipt.id}]
                                {receipt.type === 'manual' && (
                                    <>
                                        {receipt.date} {receipt.change}{' '}
                                        {receipt.message} {receipt.reason}
                                        <button
                                            onClick={() =>
                                                dispatch(
                                                    confirmPendingTransaction(
                                                        receipt.id,
                                                        'whatever',
                                                        'whatever',
                                                        'whatever'
                                                    )
                                                )
                                            }>
                                            Confirm
                                        </button>
                                        <button
                                            onClick={() =>
                                                dispatch(
                                                    rejectPendingTransaction(
                                                        receipt.id
                                                    )
                                                )
                                            }>
                                            Reject
                                        </button>
                                    </>
                                )}
                                {receipt.type.startsWith(
                                    'campaign-submission'
                                ) && (
                                    <>
                                        {receipt.title} {receipt.message}
                                        <button
                                            onClick={() =>
                                                dispatch(
                                                    dismissPendingNotification(
                                                        receipt.id
                                                    )
                                                )
                                            }>
                                            Dismiss
                                        </button>
                                    </>
                                )}
                            </li>
                        ))}
                    </ol>
                    <Headline type="h4">Navigation</Headline>
                    <Link href={routes.start}>Start</Link>
                    <br />
                    <Link href={routes.registrieren}>Registrieren</Link>
                    <br />
                    <Link href={routes.anmelden}>Anmelden</Link>
                    <br />
                    <Link href={routes.trainings}>Trainings</Link>
                    <br />
                    {allTrainings.map((training) => (
                        <Fragment key={training.id}>
                            <Link href={training.route}>
                                Training: {training.title}
                            </Link>
                            <br />
                        </Fragment>
                    ))}
                    <Link href={routes.praemien}>Praemien</Link>
                    <br />
                    <Link href={routes.team}>Team</Link>
                    <br />
                    <Link href={routes.anspruch}>Anspruch</Link>
                    <br />
                    <Link href={routes.support}>Support</Link>
                    <br />
                    <Link href={routes.profil}>Profil</Link>
                </Container>
            </Section>
        </Layout>
    );
};

export default Debug;
