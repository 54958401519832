import {createSelector} from 'reselect';

import {
    selectPendingRequests,
    selectPendingRequestsSuccess,
} from '../backend-api/selectors';
import {SUBMIT_FORM} from './actions';

export const selectIsFormSendPending = createSelector(
    selectPendingRequests,
    (pendingRequests) =>
        pendingRequests.some((request) => request.type === SUBMIT_FORM)
);
export const selectIsFormSendSuccess = createSelector(
    selectPendingRequestsSuccess,
    (pendingRequests) => pendingRequests.type === SUBMIT_FORM
);
