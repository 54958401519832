import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import {Roles, selectRoles} from '../../../model/profile';

export default function RoleSwitch({children}) {
    const roles = useSelector(selectRoles);
    return children(roles);
}

RoleSwitch.propTypes = {
    children: PropTypes.func.isRequired,
};

export const IfNoRole = ({children}) => (
    <RoleSwitch>{(roles) => (roles.length === 0 ? children : null)}</RoleSwitch>
);

IfNoRole.propTypes = {
    children: PropTypes.any,
};

export const IfUserRole = ({children}) => (
    <RoleSwitch>
        {(roles) => (roles.includes(Roles.USER) ? children : null)}
    </RoleSwitch>
);

IfUserRole.propTypes = {
    children: PropTypes.any,
};

export const IfInterimRole = ({children}) => (
    <RoleSwitch>
        {(roles) => (roles.includes(Roles.INTERIM) ? children : null)}
    </RoleSwitch>
);

IfInterimRole.propTypes = {
    children: PropTypes.any,
};

export const IfTestUserRole = ({children}) => (
    <RoleSwitch>
        {(roles) => (roles.includes(Roles.TEST_USER) ? children : null)}
    </RoleSwitch>
);

IfTestUserRole.propTypes = {
    children: PropTypes.any,
};

export const IfSalesRepresentativeRole = ({children}) => (
    <RoleSwitch>
        {(roles) =>
            roles.includes(Roles.SALES_REPRESENTATIVE) ? children : null
        }
    </RoleSwitch>
);

IfSalesRepresentativeRole.propTypes = {
    children: PropTypes.any,
};
